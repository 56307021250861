import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #003 - Eric Portelance, Slake Brewing"}
    blogUrl={"https://joincrafted.com/003-eric-portelance-slake-brewing"}
    description={
      "Learn from Eric Portelance as he talks about opening his second brewery: Slake Brewing, locally-sourced and built on ~100 acres of beautiful Prince Edward County land."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/adam-wilson-ANK5zq-g_-g-unsplash_resized.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=Check%20out%20Crafted,%20a%20newsletter%20about%20craft%20beer.%20Learn%20from%20Eric%20Portelance%20as%20he%20talks%20about%20opening%20his%20second%20brewery%3A%20Slake%20Brewing,%20locally-sourced%20and%20built%20on%20~100%20acres%20of%20beautiful%20Prince%20Edward%20County%20land.%0A%0Ahttps%3A/joincrafted.com/003-eric-portelance-slake-brewing"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A/joincrafted.com/003-eric-portelance-slake-brewing"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5",
              }}
            >
              <p>September 28, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              {/* Main */}
              <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          {/*Main body*/}
                          <tr bgcolor="#ffffff" style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;003 🍻 - Eric Portelance, Slake Brewing</h1>
                                              </div>
                                              </td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>It's September 28 which means we're in the midst of Oktoberfest. Funny how dates work. Even though most of us won't be able to partake in any of the season's normal festivals, I sincerely hope you each can enjoy some good automn beer in smaller gatherings with those whose company you enjoy.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Speaking of beer, today we get to learn from a homebrewer-turned-businessman who is putting the finishing touches on his second brewery.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>I first found out about <strong>Eric Portelance</strong> when I stumbled upon his writings on Medium, where he <a href="https://medium.com/starting-a-brewery" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">documented in six parts</a> how he started his first brewery, <a href="https://halobrewery.com" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Halo Brewery</a> in Toronto.</p>
                                              </div>
                                            </td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}><strong>Nowadays, he's investing in a totally different vision of brewery at <a href="https://www.slakebrewing.com/" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Slake Brewing</a></strong>, a craft beer beacon built on ~100 acres of beautiful Prince Edward County, opening its doors in a few weeks.
                                                <br />
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>In this edition you'll read about:
                                                </p>
                                                <ul>
                                                  <li>How brewing is just the tip of the iceberg when it comes to running a brewery
                                                  </li>
                                                  <li>Details of <strong>Slake Brewing</strong> - the locally-sourced, destination brewery that I can't wait to visit</li>
                                                  <li>What characterizes a Canadian beer, if you can get access to one
                                                  </li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                              <EmailFormInPage />
                                              </div></td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><div style={{textAlign: 'center'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>It seemed like the opportunity to start Halo Brewery really fell into your lap. Were you simply in the right place at the right time or was there more going on in your life - where you were putting yourself in a position to find an opportunity like this?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>It was a combination of factors. Like many avid homebrewers, there was always a little thought in the back of my head that opening a brewery would be a fun thing to do. This wasn’t really a serious thought until the company I was working for at the time decided to close its doors (so the Partners could take senior management jobs at Facebook) and I got laid off.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I spent some time thinking about what I wanted to do next — continue down the same career path, including maybe moving to California, or try my hands at something completely different. In the end, I figured that the severance I had been given was a gift of sorts that gave me the time and space to explore something new without much stress.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  The first serious conversations with Callum were actually about a business idea for brewing software, but that didn’t end up going too far, and we very quickly pivoted to collaborating on launching Halo. At the time, Halo was really just a name that Callum and his former homebrewing partner had been using to make beers for their friends. When we started working on it, I spent most of my time working on the business plan, looking for investors, and building the relationships and knowledge needed to start a real business. Ultimately, we managed to secure financing and sign a lease and the whole project just had a will of its own at that point and there was no turning back.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  A lot of factors had to be in place and it was a combination of luck, putting myself out there, and hard work that made it happen. I almost certainly never would have quit a good job to do something like this if I wasn’t forced into evaluating my life direction.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the biggest lesson you learned through starting Halo Brewery?
                                                </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>There are too many to count. But I always tell people that there is a big difference between making something (e.g. brewing) and making the business that makes that thing. There’s a book called The E-Myth that goes into a lot of detail about this, and I wish I had known about it before starting Halo. Brewing is a lot of fun but when I look at where I spent my time with Halo, and now also with my second brewery, Slake, it’s probably less than 5% of what is needed to actually start a brewery. So much of it is about building a team, a culture, about financing and accounting and lawyers and contracts, and about managing relationships. It’s a business like any other and you really have to be willing to put yourself in that mindset and be happy doing those “business-things.” If you don’t, you probably will have a hard time being successful and you’ll probably be miserable.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  The lessons I learned were really this combination of skills and the importance of developing them. But the biggest personal lessons I learned were around&nbsp;the importance of partner selection, emotional intelligence, communication, and adaptability to change. Things rarely go according to plan in business and you need to be responsive and flexible for it to be successful.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Nowadays you are working on launching Slake Brewing in Prince Edward County, Ontario. Can you tell us more about how this project came together and what you want this brewery to become?
                                                </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>When my current partner Greg and I sold our shares in Halo Brewery in April 2018, we spent a bit of time thinking about what we wanted to do next. Pretty much the day the sale of our shares closed, we got on a plane and spent a week in a few cities visiting some breweries and eating a ton of BBQ. It was refreshing and inspiring after the hardships we had been through and I think we re-committed (vaguely!) to trying to do something new. I think we both felt that if we didn’t take what we have learned in the brewing space and apply it to a different project that it would be a bit of a waste.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  So we slowly started bouncing around ideas for our dream brewery and decided to build it on this stunning piece of land that Greg owns in Prince Edward County. It’s just shy of 100 acres, and I’ve been coming here for years. It felt like something that should be shared with others. I moved to Prince Edward County in July 2018 and the brewery project really started to take shape in November of that year.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  At some point in this process, I had found out that my friend Nick owned a house out here as well. Nick is a really talented brewer, and is “wired” much in the same way we are. Nick spent the last several years heading up the barrel-aged beer program at <a href="https://indiealehouse.com/" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Indie Alehouse</a> and was toying with the idea of moving out to The County full time and starting his own thing. It seemed like there was a great kinship there and so the three of us became partners and we’ve been on this journey together since then.</p>
                                                <EmailFormInPage />
                                                <p style={{paddingBottom: '16px'}}>
                                                  This project is much more ambitious than Halo was. We designed and built a brand new facility from the ground-up that includes some dream features like an underground barrel cellar that is carved out of the limestone bedrock. The view is stunning and is one of the best in Prince Edward County. We want this to be a destination brewery with an experience that lets people relax and escape their lives for however long they spend with us. The beer we’re brewing is all lighter in alcohol, delicate, crisp, but nuanced. We have livestock on the farm as well and our pigs get to eat all of the spent grain from the brewing process. We work with local maltsters and 100% of the grain we use is locally-malted and grown. We have a lot of ambition and long-term plans for this place and we can’t wait to share it with everyone.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the one beer, that you've been involved in making, that you are most proud of? How was this beer born and what makes it special to you?
                                                </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I think it’s the beers we are making right now at Slake (but haven’t released yet). They will take a little while to dial in and fully realize our vision, but I am really excited about them.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  In terms of what I have been involved in making, I am probably most proud of <a href="https://untappd.com/b/halo-brewery-shapeshifter/1718163" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Halo’s Shapeshifter</a> — a beer that I named and helped conceptualize. There was a lot of internal disagreement about whether this was a style we should even make but I really felt the market was pointing in this way and I pushed pretty hard for it. It ended up being our biggest seller by a long shot.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  It’s hard to make beer that has a notable impact on a beer scene (there are so many beers out there!) but I think this one did that. Everyone is making Sour IPAs and fruited Sour IPAs now, but there weren’t any back then. Props to <a href="https://untappd.com/b/four-winds-brewing-nectarous/574077" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Four Winds’ Nectarous</a> and <a href="https://untappd.com/b/bellwoods-brewery-jelly-king/1498099" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Bellwoods’ Jelly King</a>, which were the obvious inspirations for this beer, but we took things in a bit of a different direction.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is your favorite beer right now?
                                                </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Tough one. We’ve all been working so hard here and we really appreciate lighter ABV and refreshing drinks at the end of a long day. I’ve actually been going back to a classic lately — <a href="https://untappd.com/b/great-lakes-brewery-canuck-pale-ale/855218" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">GLB’s Canuck Pale Ale</a> — and it’s really dialled in and tasty. It’s probably the best thing I can buy at the local liquor store so I end up stocking up on it a lot. But I have also taken to ordering a lot of beer online during COVID and there have been a lot of great ones I have had.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What are some characteristics of the Canadian beer scene? What exemplifies an absolutely Canadian beer?
                                                </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I really think kettle sours are the most Canadian beer trend of the last 5 years. I had tried a few kettle sours maybe 5 years ago in Oregon and a few places but it wasn’t much of a thing anywhere and those kettle sours were generally poorly made (by today’s standard), full of off-flavours and weird yogurty sour milk flavours. I think Ontario for some reason embraced these quick-soured beers more than anywhere else. Of course, these days, you can’t even find a “hype” brewery in the US that is making fruited quick-sours in tall cans and selling them for $6 a can.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Other than that, I really don’t know what Canadian beer is. It’s a really big country and we have basically no exposure to beer from other regions. The BC scene is its own thing. There’s a whole East Coast thing happening now that is great. Quebec is its own thing. Ontario is its own thing. I haven’t spent enough time in the Prairies to really comment on what’s happening out there.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Maybe then the key characteristic of the Canadian beer scene is not having very good market access to Canadian beer?  :)
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>One last question: What advice would you give someone today who wants to start their own brewery one day?
                                                </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Make sure you really want to start a business and not just have a fun hobby that is your job. For many people, you might be better off just getting a job somewhere than running a business that does that. There’s a lot of stress that comes with running a business and it’s not just a 9-5 job with a decent salary and benefits. As a business owner you’re always taking your work home with you, even if it’s just stuff rattling around in your head.</p>
                                                <p style={{paddingBottom: '16px'}}>To pull off starting a brewery, you’ll need to be a polymath with an ability to understand brewing, mechanical, electrical, construction management, marketing, budgeting, accounting, HR, contract law, and more. You’ll need to build relationships and be adaptable and build a team that is greater than the sum of its parts. If all of that appeals to you, then <em>maybe</em> you should start a brewery. It can be truly rewarding. Just keep in mind that brewing is a really tiny part of what you will spend your days doing.</p>
                                              <EmailFormInPage />
                                              </div></td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><div style={{textAlign: 'center'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>Most worthy goals don't come easy. Starting &amp; running a brewery is no exception: there's a lot of thankless work that goes into it.</p>
                                                <p style={{paddingBottom: '8px'}}>Yet Slake Brewing sounds like a goal that is well worth the hard work. It is a visionary idea - a new way of understanding what a brewery is and how it acts. Its a haven which uses the art of craft beer to create rest and escape. This is something unique that I want to experience. I don't know of another project that is thinking about beer in the same way, do you?</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{minWidth: '100%', paddingTop: '15px', paddingBottom: '15px'}}>
                                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{borderTop: '1px solid #DCD6D1', borderCollapse: 'collapse', width: '100%'}}>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <span />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                <strong>Eric</strong>, we salute your work and wish you the best on this endeavor. Thanks for taking time to share a bit of your story with us.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Thanks for reading. I enjoy this growing community and am thankful for the opportunity to get to learn with you about brewers, beers, and the stories behind them. Don't hesitate to reply to this email if you have any feedback, suggestions, or just want to say hello.</p>
                                                <p style={{paddingBottom: '16px'}}>Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p></div></td></tr></tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td></tr></tbody></table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
